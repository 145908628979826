/*============================
12. Testimonial Style CSS
==============================*/
.testimonial-item {
  background-color: $white;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.11);
  // display: flex !important;
  align-items: center;
  padding: 40px;
  outline: none;

  @media #{$lg-device} {
    padding: 30px 20px;
  }

  @media #{$xs-device} {
    display: inline-block !important;
    padding: 30px 20px;
  }

  .testimonial-txt {
    flex-basis: calc(100% - 140px);
    max-width: calc(100% - 140px);
    font-weight: 400;

    @media #{$xs-device} {
      flex-basis: 100%;
      max-width: 100%;
      text-align: center;
    }

    img {
      margin-bottom: 20px;

      @media #{$xs-device} {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .client-name {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 0;

      .designation {
        font-size: 15px;
        font-weight: 400;
        color: #6a6a6a;
      }
    }
  }
  .client{
    .client-name{
      display: inline-block;
      position: absolute;
      top:90px;
    }
  }
  
  .testimonial-thumb {
    border-radius: 50%;
    height: 110px;
    flex-basis: 110px;
    max-width: 110px;
    overflow: hidden;
    order: 1;
    display: inline-block;
    margin-right: 30px;
    

    @media #{$xs-device} {
      order: 0;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &--2 {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
    display: block !important;

    .testimonial-txt {
      font-size: 24px;
      font-weight: 300;
      max-width: 100%;

      @media #{$md-device} {
        font-size: 20px;
      }

      @media #{$sm-device} {
        font-size: 18px;
      }
      
      .client-name {
        margin-top: 25px;
        display: inline-block;
      }
    }
  }

  &--3{
    box-shadow: none;
  }
}

.testimonial-thumbnail {
  &-item {
    position: relative;
    z-index: 1;

    &:after {
      border: 15px solid #f3f4f6;
      border-bottom: 0;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 85%;
      width: 100%;
      z-index: -1;
    }

    &:before {
      //background-image: url('../img/testimonial/bg-dot.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 85%;
      width: 100%;
      z-index: -1;
    }
  }
}

.testimonial-area {
  .section-title {
    @media #{$xl-device} {
      padding-right: 50px;
    }
    h6{
      color: #5C3C9C;
      font-size: 33px;
      text-align: center;
    }
  }

  .testimonial-content-wrap {
    margin-right: -5px;

    @media #{$xl-device} {
      padding-left: 40px;
    }

    @media #{$md-device} {
      margin-top: 50px;
    }

    @media #{$sm-device} {
      margin-top: 35px;
    }
  }

  .testimonial-area-right {
    @media #{$xl-device} {
      padding-left: 60px;
    }
  }

  &--2{
    padding-top: 90px;

    @media #{$sm-device} {
      padding: 60px 0;
    }
  }
}

.testimonial-content {
  .slick-list {
    margin: -15px 0;
    padding-left: 5px;
    padding-right: 5px;

    .slick-slide {
      margin: 15px 0;
    }
  }

  &--2{
    padding-bottom: 30px;

    .slick-arrow{
      position: absolute;
      right: 30px;
      top: 100%;

      @media #{$sm-device} {
        right: 50%;
        transform: translateX(calc(-50% - 10px));
      }

      &.slick-next{
        right: 0;

        @media #{$sm-device} {
          right: 50%;
          transform: translateX(calc(-50% + 30px));
        }
      }
    }
  }

  &--3{
    .slick-dots{
      display: flex !important;
      justify-content: center;
    }
  }
}

.tagline {
  font-size: 18px;
  line-height: 1;

  strong {
    font-weight: 500;
  }

  .tag-no {
    font-weight: 600;
    position: relative;

    &:after {
      background-color: #a2a2a4;
      content: '';
      position: absolute;
      left: 0;
      bottom: -2px;
      height: 2px;
      width: 100%;
    }
  }
}

.testimonial-grid{
  .slick-list{
    margin: 0 -10px;
    .slick-slide{
      padding: 0 10px;
    }
  }
}