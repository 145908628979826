// /*===========================
// 4.1 - Main Navigation CSS
// =============================*/

// .main-menu {
//   align-content: center;
//   justify-content: flex-end;
//   width: max-content;

//   & > li {
//     margin-right: 30px;
//     position: relative;

//     &:last-child {
//       margin-right: 0;
//     }

//     & > a {
//       color: $white;
//       display: block;
//       line-height: 1;
//       letter-spacing: 1.62px;
//       text-transform: uppercase;
//       position: relative;

//       @media #{$lg-device} {
//         font-size: 14px;
//       }
//     }

//     // Sub Menu
//     &.has-submenu {
//       margin-right: 20px;
//       padding-right: 10px;
//       position: relative;

//       // Menu Item Arrows
//       // &:after {
//       //   color: $white;
//       //   content: '\f107';
//       //   font-size: 16px;
//       //   line-height: 1;
//       //   font-family: $fa-icon;
//       //   position: absolute;
//       //   right: -5px;
//       //   top: 0;
//       // }

//       // Sub Menu
//       .submenu-nav {
//         background-color: $white;
//         box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
//         border-bottom: 2px solid $brandColor;
//         position: absolute;
//         left: -25px;
//         top: 100%;
//         transform: translateY(50px);
//         transition: 0.4s;
//         opacity: 0;
//         visibility: hidden;
//         pointer-events: none;
//         min-width: 250px;
//         margin-top: 40px;
//         z-index: 99;

//         @media #{$lg-device} {
//           min-width: 220px;
//           margin-top: 38px;
//         }

//         &:before {
//           content: '';
//           position: absolute;
//           height: 40px;
//           width: 100%;
//           left: 0;
//           bottom: 100%;
//         }

//         & > li {
//           & > a {
//             color: $paragraph;
//             display: block;
//             font-size: 15px;
//             letter-spacing: inherit;
//             text-transform: capitalize;
//             padding: 10px 20px;
//           }

//           &:hover {
//             & > a {
//               background-color: $brandColor;
//               color: $white;
//             }
//           }
//         }

//         // Mega Menu
//         &-mega {
//           display: flex;
//           margin-left: -300px;
//           padding: 0;
//           width: 1080px;

//           @media #{$lg-device} {
//             width: 960px;
//             margin-left: -245px;
//           }

//           .mega-menu-item {
//             border-right: 1px solid $borderColor;
//             padding: 30px;
//             flex-basis: 25%;

//             &:last-child {
//               border-right: 0;
//             }

//             & > a {
//               display: none;
//             }

//             ul {
//               li {
//                 margin-bottom: 10px;

//                 &:last-child {
//                   margin-bottom: 0;
//                 }

//                 a {
//                   color: $paragraph;
//                   font-size: 15px;
//                   line-height: 1;

//                   &:hover {
//                     color: $brandColor;
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }

//       .second-submenu-nav {
//         background-color: $white;
//         box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
//         border-bottom: 2px solid $brandColor;
//         position: absolute;
//         left: 250px;
//         top: 0px;
//         transform: none;
//         transition: 0.4s;
//         opacity: 1;
//         visibility: visible;
//         pointer-events: visible;
//         min-width: 250px;
//         margin-top: 40px;
//         z-index: 100;

//         @media #{$lg-device} {
//           min-width: 220px;
//           margin-top: 38px;
//         }

//         &:before {
//           content: '';
//           position: absolute;
//           height: 40px;
//           width: 100%;
//           left: 0;
//           bottom: 100%;
//         }

//         & > li {
//           & > a {
//             color: $paragraph;
//             display: block;
//             font-size: 15px;
//             letter-spacing: inherit;
//             text-transform: capitalize;
//             padding: 10px 20px;
//           }

//           &:hover {
//             & > a {
//               background-color: $brandColor;
//               color: $white;
//             }
//           }
//         }

//         // Mega Menu
//         &-mega {
//           display: flex;
//           margin-left: -300px;
//           padding: 0;
//           width: 1080px;

//           @media #{$lg-device} {
//             width: 960px;
//             margin-left: -245px;
//           }

//           .mega-menu-item {
//             border-right: 1px solid $borderColor;
//             padding: 30px;
//             flex-basis: 25%;

//             &:last-child {
//               border-right: 0;
//             }

//             & > a {
//               display: none;
//             }

//             ul {
//               li {
//                 margin-bottom: 10px;

//                 &:last-child {
//                   margin-bottom: 0;
//                 }

//                 a {
//                   color: $paragraph;
//                   font-size: 15px;
//                   line-height: 1;

//                   &:hover {
//                     color: $brandColor;
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }

//       // After Hover Sub Menu
//       &:hover {
//         & > .submenu-nav {
//           transform: none;
//           opacity: 1;
//           visibility: visible;
//           pointer-events: visible;
//         }
//       }
//       // &:hover {
//         .submenu-nav:hover >.second-submenu-nav {
//           transform: none;
//           opacity: 1;
//           visibility: visible;
//           pointer-events: visible;
//         }
//       // }
//     }
//     // &.ropdown {
//     //    &:after {
//     //     color: $white;
//     //     content: '\f107';
//     //     font-size: 16px;
//     //     line-height: 1;
//     //     font-family: $fa-icon;
//     //     position: absolute;
//     //     right: -5px;
//     //     top: 0;
//     //   }
//     // }
//   }
// }

// /* Responsive Mobile Menu */
// .res-mobile-menu {
//   background-color: $brandColor;
//   display: block !important;
//   padding: 20px 30px;

//   .mobile-nav{
//     li {
//       border-bottom: 1px solid rgba(255, 255, 255, .2);
//       position: relative;
//       list-style: none;

//       &:last-child {
//         border-bottom: 0;
//       }

//       a {
//         color: $white;
//         font-size: 16px;
//         padding: 15px 0;
//         margin: 0;
//         text-transform: capitalize;
//         position: relative;

//         &:hover {
//           color: $white;
//           background-color: transparent;
//         }
//       }

//       img {
//         display: none;
//       }

//       ul {
//         border-top: 1px solid rgba(255, 255, 255, .2);
//         margin: 0;
//         padding-left: 10px;

//         li {

//           &.mega-menu-item{
//             & > a{
//               font-weight: 600;
//             }
//           }

//           a {
//             font-size: 13px;
//             color: rgba($white, 0.7);
//           }
//         }
//       }
//     }
//   }
// }

// .dropdown-menu li {
//   position: relative;
//   }
//   .dropdown-menu .dropdown-submenu {
//   display: none;
//   position: absolute;
//   left: 100%;
//   top: -7px;
//   }
//   .dropdown-menu .dropdown-submenu-left {
//   right: 100%;
//   left: auto;
//   }
//   .dropdown-menu > li:hover > .dropdown-submenu {
//   display: block;
//   }

.navbar-nav li:hover > ul.dropdown-menu {
    display: block;
}
.dropdown-submenu {
    position:relative;
}
.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top:-6px;
}

/* rotate caret on hover */
.dropdown-menu > li > a:hover:after {
    text-decoration: underline;
    transform: rotate(-90deg);
} 